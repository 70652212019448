
import { BCheckbox, BInput } from 'buetify/lib/components';
import BButton from 'buetify/lib/components/button/BButton';
import BField from 'buetify/lib/components/form/field';
import BModal from 'buetify/lib/components/modal/BModal';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'simple-modal-example',
	components: {
		BModal,
		BButton,
		BField,
		BInput,
		BCheckbox
	}
});
