
import BButton from 'buetify/lib/components/button/BButton';
import BBox from 'buetify/lib/components/layout/box/BBox';
import BModal from 'buetify/lib/components/modal/BModal';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'simple-modal-example',
	components: {
		BModal,
		BButton,
		BBox
	}
});
